/* disable click function until JS loaded */
$('#submit').addClass('unbind');

$(function() {
	// login in authentication functions with SRP
	loginForm.init();
	// load any breaking news values that were queued before DOM was ready.
	InfoPanel.init();
	EmbeddedLogin.init();
});


var loginForm = {
	accountName: $('#accountName'),
	alertCookie: $('#cookie-check'),
	alertHolder: $('#display-errors'),
	alertJsHolder: $('#js-errors'),
	csrfTokenFields: $('input[name="csrftoken"]'),
	password: null,
	passwordForm: $('#password-form'),
	passwordFill: ".",
	submitButton: $('#submit'),
	legalAcceptanceLabel: $('#legalAcceptance-label'),
	srpClientSession: null,
	srpClientCredentials: null,
	inputPassword: $("#password"),
	inputUpgradeVerifier: $("#upgradeVerifier"),
	inputUseSrp: $("#useSrp"),
	inputSrpEnabled: $("#srpEnabled"),
	inputPublicA: $("#publicA"),
	inputClientEvidenceM1: $("#clientEvidenceM1"),
	networkErrorMessage: $('body').attr("data-network-error-message"),
	thirdPartyLoginButtons: $('form.third-party-login button'),
	// Initial load time of the form.
	loadedTime: new Date().getTime(),
	// Number of seconds to wait before refreshing CSRF token.
	// This value should be well below session expiration time.
	csrfTokenRefreshIntervalMillis: 10 * 60 * 1000,
	focusDelay: 500,
	squelchEmbeddedCallbacks: $('.login')[0].dataset.squelchEmbeddedCallbacks === 'true',


	preventDefault: function(e) {
		e.preventDefault();
	},

	init: function() {
		this.setSubmitButtonDisabled();
		this.checkCookie();
		this.setSrpAjaxCall();
		this.setCaptcha(loginForm.passwordForm);
		this.initThirdPartyLoginButtons();
		this.initPasswordFields();
		this.setFocus();
		this.showInitialErrors();
	},

	setSrpAjaxCall: function() {
		this.submitButton.bind('click', this.preventDefault).click(function() {
			try {
				loginForm.getSrpData(Core.locale);
			} catch (ex) {
				setTimeout(function() {
					loginForm.submitButton.removeAttr('disabled').unbind('click').click();
				});
			};
		});
	},

	setSubmitButtonDisabled: function() {
		var that = this;
		this.submitButton.removeClass('unbind');

		// If there's a legal checkbox in login screen (This only applies to appEnv CN for now),
		// disable both login button and NetEase icon by default.
		if (this.legalAcceptanceLabel.hasClass('checkbox-label')) {
			that.disableLoginButtons();
		}
		// If there's a click event, disable/enable buttons accordingly.
		$(this.legalAcceptanceLabel.click(function() {
			// If the checkbox is already checked that means this user is trying to uncheck it. We need to disable both buttons.
			if(that.legalAcceptanceLabel.hasClass('checked')) {
				that.disableLoginButtons();
			}
			else {
				that.enableLoginButtons();
			}
		}));
	},

	/** cookie disabled alert **/
	checkCookie: function() {
		var enabledCookie = navigator.cookieEnabled;

		if (!enabledCookie) {
			$(loginForm.alertCookie).removeClass('hide');
			$(loginForm.alertJsHolder).removeClass('hide')
		}
	},

	/** set auto focus on the input field **/
	setFocus: function() {
		var autoFocusEnabled = ($('body').attr("data-autofocus-enabled") === "true");

		/** only works on web env, not for the mobile **/
		if (autoFocusEnabled) {
			/** if there are field errors, put focus on those **/
			/** wait for call stack is empty in case focus events occur after this **/
			const errorField = document.querySelector(".field-error");

			if (errorField && errorField.nodeName === "INPUT") {
				setTimeout(function () {
					errorField.focus();
					errorField.click();
				}, this.focusDelay);
				return;
			}

			if (loginForm.accountName.val()) {
				loginForm.inputPassword.focus();
			} else {
				loginForm.accountName.focus();
			}
		}
	},

	showInitialErrors: function() {
		/** if there is an initial display error **/
		/** this triggers the aria alert for screen readers **/
		var alertHolder = this.alertHolder;
		/**
		 * 	Here we are overloading squelchEmbeddedCallbacks to also prevent initial errors from being displayed.
		 * 	Because these errors are provided during SRP AJAX, it is not possible to re-scale the Phoenix window
		 * 	and as such we must not display these errors during STANDALONE_LOGIN
		 */
		if (alertHolder.length) {
			setTimeout(function () {
				alertHolder.show();
			}, this.focusDelay - 100);
		}
	},

	/** refresh captcha display **/
	setCaptcha: function(form) {
		if (form.hasClass('captcha-required')) {
			$('#captcha-anchor').click(function(evt) {
				evt.preventDefault();
				$("#sec-string").attr('src', '/login/captcha.jpg?' + new Date().getTime());
			});
		}
	},

	/** srp Authentication **/
	getSrpData: function(language) {
		var that = this;
		var username = loginForm.passwordForm.find('input[name="accountName"]').val();

		$.ajax({
			method: 'POST',
			url: "/login/srp?csrfToken=true",
			data: JSON.stringify({
				inputs: [
					{
						input_id: "account_name",
						value: username
					}
				]
			}),
			dataType: 'json',
			beforeSend: function(request) {
				request.setRequestHeader("Accept", "application/json");
				request.setRequestHeader("Content-Type", "application/json");
				request.setRequestHeader("Accept-Language", language);
			}
		}).done(function(data) {

			loginForm.onSuccess(data).then(function () {
				that.submitForm();
			}).catch(function (err) {
				that.submitForm();
			});
		}).fail(function(data) {
			loginForm.onFail(data);
		});
	},

	submitForm: function() {
		loginForm.submitButton.removeAttr('disabled').unbind('click').click().addClass('unbind');
	},

	/** get srp data success **/
	onSuccess: function(srpParams) {
		if (this.inputSrpEnabled.val() === "false") {
			return Promise.resolve();
		}

		var self = this;
		loginForm.updateCsrfTokenFields(srpParams.csrf_token);
		self.password = self.inputPassword.val();
		self.srpClientSession = new srp6aRoutines.ClientSession(
			srpParams.modulus,
			srpParams.generator,
			srpParams.hash_function,
			srpParams.version,
			srpParams.iterations,
			srpParams.eligible_credential_upgrade,
			window.serverResourceUrl);
		return self.srpClientSession.step1(
			srpParams.username,
			self.password,
			srpParams.salt,
			srpParams.public_B
		).then(function (srpClientCredentials) {
					self.srpClientCredentials = srpClientCredentials;
					// Clear password and change placeholder when input value is not null
					// and password is not eligible for an upgrade
					if (self.password !== null) {
						self.inputPassword.val(Array(self.password.length + 1).join(self.passwordFill));
					}

					if (srpParams.version === 1 && srpParams.eligible_credential_upgrade && self.inputSrpEnabled.val() !== "false") {
						return new Promise(function(resolve) {
							var upgradeWorker = new Worker(window.upgradeResourceUrl);
							upgradeWorker.onmessage = function(e) {
								var encodedVerifierHex = e.data[0];
								self.inputUpgradeVerifier.val(encodedVerifierHex);
								self.fillSrpFields();
								upgradeWorker.terminate();
								resolve();
							};
							upgradeWorker.postMessage([window.accountPasswordUrl, srpParams, self.password]);
						});
					} else {
						self.fillSrpFields();
					}
				});
	},

	fillSrpFields: function() {
		// Set SRP public A and client evidence message M1.
		this.inputPublicA.val(this.srpClientCredentials.publicA.toString(16));
		this.inputClientEvidenceM1.val(this.srpClientCredentials.clientEvidenceM1.toString(16));
		// Tell server that we will be using SRP.
		this.inputUseSrp.val("true");
	},

	errorCleanup: function() {
		var $helper = $('.error-helper');

		//remove if there are already generic error displayed
		if (loginForm.alertHolder.length) {
			loginForm.alertHolder.remove();
		}

		// remove inline helper error from inputs
		if($helper.text().trim().length) {
			$helper.empty().hide();
		}

		// remove any status error classes and attributes
		$('.status-warning').removeClass('status-warning');
		$('.status-error').removeClass('status-error');
		$('.field-error').removeClass('field-error');
		$('.control-error').removeClass('control-error');
		$('input[aria-invalid=true]').attr('aria-invalid', 'false');
	},

	/** get srp data fail **/
	onFail: function(data) {
		var err = {
			errorCode: "",
			supportCode: "",
			subtextHtml: "",
			errorMessage: "",
			errorStatus: "error",
			errorAria: "",
		};
		// when network connection failed, use custom error expose to app
		if (!navigator.onLine || data.status == 0) {
			err.errorCode = "NETWORK_ERROR"
			err.errorMessage = loginForm.networkErrorMessage;
		} else {
			var errorData = JSON.parse(data.responseText);
			err.errorCode = errorData.error_code || "";
			err.supportCode = errorData.support_error_code || "";
			err.errorMessage = errorData.error_message;
			err.errorMessageSubtext = errorData.error_message_subtext || "";
			err.errorMessageHelper = errorData.error_message_helper || "";
			err.errorStatus = errorData.error_status.toLowerCase() || "error";
			err.errorAria = err.errorMessage;
		}

		// clean up all previous errors
		this.errorCleanup();

		if (err.errorMessageSubtext) {
			err.subtextHtml = "<p class='error-message-subtext' tabIndex='0'>" + err.errorMessageSubtext + "</p>";
			err.errorAria += err.errorMessageSubtext;
		}

		err.errorMessageHtml = "<p class='error-message-heading' tabIndex='0'>" + err.errorMessage + "</p>" + err.subtextHtml;

		if (err.supportCode) {
			var supportAria = loginForm.alertJsHolder.attr('data-support-aria');
			var supportAriaAttr = supportAria ? 'aria-label=\"' + supportAria + '\"': '';
			err.errorMessageHtml += "<a tabIndex='0' href='" + Core.secureSupportUrl + 'article/' + err.supportCode + "' rel='external' target='_blank' " + supportAriaAttr + " role='link'>" + err.supportCode + "</a>"
		}

		if (err.errorMessageHelper && errorData.input_id) {
			err.errorMessageHtml += "<p class='hide-text'>" + err.errorMessageHelper + "</p>";
		}

		// set error message
		loginForm.alertJsHolder.html(err.errorMessageHtml).removeClass('hide').removeClass('alert-error');
		loginForm.alertJsHolder.addClass('alert-' + err.errorStatus);

		if (err.errorMessageHelper && errorData.input_id) {
			var helperFieldId = window.Utility.getInputId(errorData.input_id);
			var $fieldWithError = $('#' + helperFieldId);
			var $helperElm = $fieldWithError.siblings('.error-helper');
			$fieldWithError.addClass('status-' + err.errorStatus);
			$fieldWithError.addClass('field-error');
			$fieldWithError.attr('aria-invalid', 'true');
			$helperElm
				.html(err.errorMessageHelper)
				.removeClass('hide')
				.addClass('status-' + err.errorStatus)
				.attr('style', 'display: block;');
		}

		// Clear the password.
		this.inputPassword.val('');

		loginForm.submitButton.button('reset');

		// expose errors to phoenix
		if (EmbeddedLogin.errorHandlerRegistration && !this.squelchEmbeddedCallbacks) {
			EmbeddedLogin.errorHandlerRegistration(err.errorCode, err.supportCode, err.errorMessage);
		}

		// if field error, put focus on that field
		var focusedElm;
		if (err.errorMessageHelper && errorData.input_id && $fieldWithError) {
			focusedElm = $fieldWithError;
		} else {
			focusedElm = loginForm.accountName;
		}

		setTimeout(function () {
			focusedElm.click().focus();
		}, this.focusDelay);
	},

	/**
	 * For each language the button width would be different. This sets the padding-right of the password field
	 * based on the calculated button width so the password doesn't overlap the show/hide button
	 */
	calculateTogglePadding: function(inputPassword, button) {
		var getCssNumberValue = function(cssValue) {
			return parseInt(cssValue.replace(/[^-?\d*]/g, ''));
		};

		var buttonWidth = getCssNumberValue(button.css('padding-left')) + button.width() + getCssNumberValue(button.css('padding-right'));
		if (buttonWidth > 0) {
			inputPassword.css("padding-right", buttonWidth + 'px');
		}
	},

	/**
	 * Updates all "csrftoken" hidden input fields (login view contains multiple forms)
	 * with the given csrfToken. This function should be called prior to form submission
	 * to make sure that CSRF tokens used are always valid, even if the session times out.
	 */
	updateCsrfTokenFields: function(csrfToken) {
		[this.csrfTokenFields].forEach(function(field) {
			field.val(csrfToken);
		});
	},

	/**
	 * Reloads CSRF token with an AJAX request and establishes a new session associated with
	 * that CSRF token if the session has expired.
	 */
	refreshCsrfToken: function(event) {
		$.ajax({
			method: 'POST',
			url: '/login/csrf-token',
			async: false
		}).done(function(csrfToken) {
			loginForm.updateCsrfTokenFields(csrfToken);
			$(event.target).unbind('click').click();
		});
	},

	/**
	 * Bind events to third party login buttons.
	 */
	initThirdPartyLoginButtons: function() {
		var currentTime = new Date().getTime();
		[this.thirdPartyLoginButtons].forEach(function(button) {
			button.bind('click', this.preventDefault).click(function(event) {
				loginForm.refreshCsrfToken(event);
			});
		});
	},

	initPasswordFields: function() {
		if (BlzPassword != null) {
			var passwordField = new BlzPassword(document.querySelector("#password"));
			passwordField.init();
		}
	},

	disableLoginButtons: function() {
		$('#submit').addClass('disabled'); //login button
		$('#ntes').addClass('disabled'); // ntes icon (button)
	},

	enableLoginButtons: function() {
		$('#submit').removeClass('disabled'); //login button
		$('#ntes').removeClass('disabled'); // ntes icon (button)
	}
};

/**
 * Object to manage left aligned info panel
 */
var InfoPanel = {
	// CSS classes used to style dom
	cssHide: 'hide',
	cssShow: 'show',
	cssGrid: 'info-active',
	// UI selectors
	leftPanel: '#info-wrapper',
	rightPanel: '#login-wrapper',
	phoneInfoToggle: '#info-phone-toggle',
	phoneInfoClose: '#info-phone-close',
	title: null,
	body: null,
	enabled: null,
	domReady: false,

	init: function() {
		// initialize jquery objects
		this.domReady = true;
		this.$leftPanel = $(this.leftPanel);
		this.$rightPanel = $(this.rightPanel);
		this.$mobileToggle = $(this.phoneInfoToggle);
		this.$mobileClose = $(this.phoneInfoClose);
		// show infopanel if toggle was already called.
		if (this.enabled) {
			this.toggle(this.enabled, this.title, this.body);
		}
		this.enableMobile();
	},

	toggle: function(toggle, title, body) {
		// cache values for use when DOM is ready
		this.title = title;
		this.body = body;
		this.enabled = toggle;

		var status = "InfoPanel.toggle called.";
		// only allow actions throw if DOM exists
		if (this.domReady) {
			if (toggle) {
				// populate information
				this.$leftPanel.find('.info-title').html(title);
				this.$leftPanel.find('.info-body').html(body);
				this.$mobileToggle.html(title);

				// show Left Panel, but keep hidden for mobile
				this.$leftPanel
					.removeClass(this.cssHide)
				// turn main content into 2 column design
				this.$rightPanel
					.addClass(this.cssGrid);
				// show mobile only info panel toggle.
				this.$mobileToggle
					.removeClass(this.cssHide)

			} else {
				// hide info panel
				this.$leftPanel
					.addClass(this.cssHide);
				// center align main content
				this.$rightPanel
					.removeClass(this.cssGrid);
				// hide mobile only info panel toggle
				this.$mobileToggle
					.addClass(this.cssHide);
			}
			status += "Dom ready."
		}
		return status;
	},

	/**
	 * Hides and shows breaking news based on mobile only button
	 */
	enableMobile: function() {
		var self = this;
		// helper function to stop event prop. and add and remove class.
		var toggleClass = function(event, showElement, hideElement) {
			event.stopPropagation();
			event.preventDefault();

			showElement.removeClass(InfoPanel.cssHide);
			hideElement.addClass(InfoPanel.cssHide);
		};

		// click listener for info panel toggle in mobile form factor
		this.$mobileToggle.on("click", function(e) {
				toggleClass(e, self.$leftPanel, self.$rightPanel);
			}
		);
		// click listener to close info panel in mobile factor.
		this.$mobileClose.on("click", function(e) {
				toggleClass(e, self.$rightPanel, self.$leftPanel);
			}
		)
	}
}

/** Battle.net App is using this function to check and fill input on App login screen. **/

var EmbeddedLogin = {

	inputAccountName: null,
	inputPassword: null,
	inputPersistLogin: $('#persistLogin'),
	inputAccountNameId: 'accountName',
	inputPasswordId: 'password',
	errorContainer: null,
	errorContainerId: '#display-errors',
	jsErrorContainer: null,
	jsErrorContainerId: '#js-errors',
	errorHandlerRegistration: null,

	init: function() {
		this.inputAccountName = document.getElementById(this.inputAccountNameId);
		this.inputPassword = document.getElementById(this.inputPasswordId);
		this.errorContainer = $(this.errorContainerId);
		this.jsErrorContainer = $(this.jsErrorContainerId);
		return this;
	},

	getSubmitButton: function() {
		return loginForm.submitButton[0];
	},

	fillAccountName: function(accountName) {
		if (accountName) {
			this.inputAccountName.value = accountName.substring(0, Math.min(320, accountName.length));
		} else {
			this.inputAccountName.value = '';
		}
		return this;
	},

	fillPassword: function(password) {
		if (password) {
			this.inputPassword.value = password.substring(0, Math.min(128, password.length));
		} else {
			this.inputPassword.value = '';
		}
		return this;
	},

	setPersistLogin: function(checked) {
		if (this.inputPersistLogin) {
			this.inputPersistLogin.prop('checked', checked);
		}
	},

	registerErrorHandler: function(errorHandler) {
		this.errorHandlerRegistration = errorHandler;
	}
};
